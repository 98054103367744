import { useEffect, useState } from "react";
import { Button, Loader } from "../Styles";
import { toast } from "react-toastify";
import { listarTodosCandidatos, listarTodosContatos } from "../../services/Atendimento";
import { modeloCandidatoContatoComunicadoListar, modeloContatoComunicadoListar } from "../../interfaces/atendimento";

function ModalAdicionarContato({ titulo, processando, funcaoSubmit, onClose, contatosAdicionados, candidatosAdicionados, setContatoSelecionado, setCandidatoSelecionado, comunicado }: any) {
  const [modalContato, setModalContatos] = useState("")
  const [contatos, setContatos] = useState<modeloContatoComunicadoListar[]>([])
  const [candidatos, setCandidatos] = useState<modeloCandidatoContatoComunicadoListar[]>([])

  useEffect(() => {
    const listarContatos = async () => {

      try {
        const resposta = await listarTodosContatos();
        setContatos(resposta.data);
      } catch (error) {
        toast.error("Erro ao listar os contatos.")
      }
    };

    listarContatos();
  }, []);

  useEffect(() => {
    const listarCandidatos = async () => {
      let notificacaoEmail = comunicado.email;
      let notificacaoWhatsapp = comunicado.whatsapp;

      try {
        const resposta = await listarTodosCandidatos(notificacaoEmail, notificacaoWhatsapp);
        setCandidatos(resposta.data);
      } catch (error) {
        toast.error("Erro ao listar os contatos.")
      }
    };

    listarCandidatos();
  }, []);

  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        {modalContato === "" && <><span className='novoContainerTitulo'><h1>
          Selecione uma das opções abaixo
        </h1>
        </span>
          <span className="botoes-container-modal">
            <Button type='button' cor='solicitarUsuario' isLoading={processando} onClick={() => setModalContatos("candidato")}>Candidato</Button>
            <Button type='submit' cor='solicitarUsuario' isLoading={processando} onClick={() => setModalContatos("contato")}>Contato</Button>
          </span>
        </>}
        {modalContato !== "" && <form onSubmit={funcaoSubmit}>
          <span className='novoContainerTitulo'>
            <h1>{titulo} {modalContato}</h1>
          </span>
          <section>
            {modalContato === "contato" && <span>
              <select name="" defaultValue="" className="select-container-modal" onChange={(e) => setContatoSelecionado(e.target.value)}>
                <option disabled value="">Selecione um Contato</option>
                {contatos.map((contato) => {
                  const jaAdicionado = contatosAdicionados.some((adicionado: any) => adicionado.idContato === contato.id);
                  return (
                    <option key={contato.id} value={contato.id} disabled={jaAdicionado}>
                      {contato.nome} {jaAdicionado ? "(Já adicionado)" : ""}
                    </option>
                  );
                })}
              </select>
            </span>}
            {modalContato === "candidato" && <span>
              <select name="" defaultValue="" className="select-container-modal" onChange={(e) => setCandidatoSelecionado(e.target.value)}>
                <option disabled value="">Selecione um Candidato</option>
                {candidatos.map((candidato) => {
                  const jaAdicionado = candidatosAdicionados.some((adicionado: any) => adicionado.idCandidato === candidato.id);
                  return (
                    <option key={candidato.id} value={candidato.id} disabled={jaAdicionado}>
                      {candidato.nomeCompleto} {jaAdicionado ? "(Já adicionado)" : ""}
                    </option>
                  );
                })}

              </select>
            </span>}
          </section>
          <span className="botoes-container-modal">
            <Button type='button' cor='cancelar' isLoading={processando} onClick={() => onClose("")}>Fechar</Button>
            <Button type='submit' cor='salvar' isLoading={processando}>
              {!processando && 'Adicionar'}
              {processando && <Loader isLoading={processando} />}
            </Button>
          </span>
        </form>}
      </section>
    </div>
  );
}

export default ModalAdicionarContato;