import { Routes as WrapperRoutes, Route, BrowserRouter } from 'react-router-dom'
import Layout from '../components/layout'
import ComponentePrivado from '../middleware/ComponentePrivado'
import Login from '../pages/Auth'
import NovaOrganizacao from '../pages/CadastroOrganizacao'
import Cadastros from '../pages/Cadastros'
import Dashboard from '../pages/Dashboard'
import DashboardFinanceiro from '../pages/DashboardFinanceiro'
import Usuarios from '../pages/Usuarios'
import Organizacao from '../pages/DadosOrganizacao'
import Tarefas from '../pages/Tasks'
import Leads from '../pages/Leads'
import Suporte from '../pages/Suporte'
import Painel from '../pages/MeuPainel'
import ModelosProcessos from '../pages/ModelosProcessos'
import Proccessos from '../pages/Processos'
import ContasBancarias from '../pages/ContasBancarias'
import Centros from '../pages/Centros'
import Categorias from '../pages/Categorias'
import Lancamentos from '../pages/Lancamentos'
import Dre from '../pages/Dre'
import ClientesGesttor from '../pages/ClientesGesttor'
import SuporteGesttor from '../pages/SuporteGesttor'
import IntegracoesPage from '../pages/Integracoes'
import DocumentosDigitais from '../pages/DocumentosDigitais'
import BancoTalentos from '../pages/BancoTalentos'
import ModelosContratos from '../pages/ContratosModelos'
import Contratos from '../pages/Contratos'
import QuestionarioDisc from '../pages/QuestionarioDisc'
import ProcessosSeletivos from '../pages/ProcessosSeletivos'
import VisualizarProcessosSeletivos from '../pages/VisualizarProcessosSeletivos'
import Nps from '../pages/Nps'
import DashboardProcessoSeletivoPage from '../pages/DashboardProcessoSeletivo'
import DashboardCandidatos from '../pages/DashboardCandidatos'
import Comunicados from '../pages/Comunicados'
import Contato from '../pages/Contatos'

function Routes() {
    return (
        <BrowserRouter>
            <Layout>
                <WrapperRoutes>
                    <Route path='/dashboard' element={<ComponentePrivado><Dashboard /></ComponentePrivado>} />
                    <Route path='/dashboard/financeiro' element={<ComponentePrivado><DashboardFinanceiro /></ComponentePrivado>} />
                    <Route path='/dashboard/processos-seletivos' element={<ComponentePrivado><DashboardProcessoSeletivoPage /></ComponentePrivado>} />
                    <Route path='/dashboard/candidatos' element={<ComponentePrivado><DashboardCandidatos /></ComponentePrivado>} />
                    <Route path='/cadastros/:tipo' element={<ComponentePrivado><Cadastros /></ComponentePrivado>} />
                    <Route path='/usuarios' element={<ComponentePrivado><Usuarios /></ComponentePrivado>} />
                    <Route path='/organizacao' element={<ComponentePrivado><Organizacao /></ComponentePrivado>} />
                    <Route path='/tarefas' element={<ComponentePrivado><Tarefas /></ComponentePrivado>} />
                    <Route path='/leads' element={<ComponentePrivado><Leads /></ComponentePrivado>} />
                    <Route path='/comunicados' element={<ComponentePrivado><Comunicados /></ComponentePrivado>} />
                    <Route path='/contatos' element={<ComponentePrivado><Contato /></ComponentePrivado>} />
                    <Route path='/ajuda' element={<ComponentePrivado><Suporte /></ComponentePrivado>} />
                    <Route path='/painel' element={<ComponentePrivado><Painel /></ComponentePrivado>} />
                    <Route path='/modelosProcessos' element={<ComponentePrivado><ModelosProcessos /></ComponentePrivado>} />
                    <Route path='/processos' element={<ComponentePrivado><Proccessos /></ComponentePrivado>} />
                    <Route path='/contas' element={<ComponentePrivado><ContasBancarias /></ComponentePrivado>} />
                    <Route path='/centros' element={<ComponentePrivado><Centros /></ComponentePrivado>} />
                    <Route path='/categorias' element={<ComponentePrivado><Categorias /></ComponentePrivado>} />
                    <Route path='/lancamentos' element={<ComponentePrivado><Lancamentos /></ComponentePrivado>} />
                    <Route path='/dre' element={<ComponentePrivado><Dre /></ComponentePrivado>} />
                    <Route path='/gesttor/:cliente' element={<ComponentePrivado><ClientesGesttor /></ComponentePrivado>} />
                    <Route path='/gesttor/ajuda' element={<ComponentePrivado><SuporteGesttor /></ComponentePrivado>} />
                    <Route path='/integracoes' element={<ComponentePrivado><IntegracoesPage /></ComponentePrivado>} />
                    <Route path='/documentos-digitais' element={<ComponentePrivado><DocumentosDigitais /></ComponentePrivado>} />
                    <Route path='/banco-talentos' element={<ComponentePrivado><BancoTalentos /></ComponentePrivado>} />
                    <Route path='/questionario-disc' element={<ComponentePrivado><QuestionarioDisc /></ComponentePrivado>} />
                    <Route path='/processos-seletivos' element={<ComponentePrivado><ProcessosSeletivos /></ComponentePrivado>} />
                    <Route path='/processo-seletivo/:idProcesso' element={<ComponentePrivado><VisualizarProcessosSeletivos /></ComponentePrivado>} />
                    <Route path='/processo-seletivo-nps' element={<ComponentePrivado><Nps /></ComponentePrivado>} />
                    <Route path='/modelos-contratos' element={<ComponentePrivado><ModelosContratos /></ComponentePrivado>} />
                    <Route path='/contratos' element={<ComponentePrivado><Contratos /></ComponentePrivado>} />
                    <Route path='*' element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/novaOrganizacao' element={<NovaOrganizacao />} />
                </WrapperRoutes>
            </Layout>
        </BrowserRouter>
    )
}

export default Routes