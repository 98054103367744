import { useState, FormEvent, useContext, useEffect, ChangeEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { moedaMascara, converterNumero } from '../../helpers'
import { ModalLancamentosDados, rateio } from '../../interfaces/lancamentos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarCadastros } from '../../services/cadastros'
import { lisatarCategorias } from '../../services/categorias'
import { toast } from 'react-toastify'
import { listarContas } from '../../services/contas'
import { novoLancamento, atualizarLancamento } from '../../services/lancamentos'
import { baixarLancamento, listarBaixas, apagarBaixa } from '../../services/baixas'
import { novaCobranca, visualizarCobranca, cancelarCobranca, enviarCobrancaWa } from '../../services/cobrancas'
import { listaCategoriasResposta } from '../../interfaces/categorias'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { contasListaResposta } from '../../interfaces/contas'
import { baixaListaResposta } from '../../interfaces/baixas'
import { Button, Loader } from '../Styles'
import avatarDefault from '../../assets/img/avatarDefault.png'
import DivSituacao from '../DivSituacao'
import boletoImg from '../../assets/img/codigoBarras.png'
import copiarImg from '../../assets/img/copiar.png'
import waImg from '../../assets/img/whatsappCobranca.png'
import apagarImg from '../../assets/img/excluir.png'
import taskImg from '../../assets/img/tarefas.png'
import './modallancamentos.css'
import '../../styles/modal.css'


function ModalLancamento ({ lancamento }: ModalLancamentosDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { pathname, search } = location
    const params = new URLSearchParams(search)
    const tipo = params.get('tipo')
    const recorrenciaLancamento = params.get('recorrencia')
    const inadimplencia = params.get('inadimplencia')

    const paramsUrl = new URLSearchParams(search)
    const id = paramsUrl.get('id')

    const [contasBancarias, setContasBancarias] = useState<contasListaResposta[]>([])
    const [contaBaixa, setContaBaixa] = useState('')
    const [valorBaixa, setValorBaixa] = useState(0)
    const [dataTransacao, setDataTransacao] = useState('')
    const [baixasLancamentos, setBaixasLancamentos] = useState<baixaListaResposta []>([])
    const [competencia, setCompetencia] = useState(lancamento?.competencia)
    const [vencimento, setVencimento] = useState(lancamento?.vencimento)
    const [cadastro, setCadastro] = useState (lancamento?.cadastro.id)
    const [cadastros, setCadastros] = useState<cadastrosRespostaPayload[]>([])
    const [categorias, setCategorias] = useState<listaCategoriasResposta[]>([])
    const [valorTotal, setValorTotal] = useState(lancamento?.valorTotal)
    const [valorQuitado] = useState(lancamento?.valorQuitado)
    const [saldoQuitar] = useState(lancamento?.saldoQuitar)
    const [descricao, setDescricao] = useState (lancamento?.descricao)
    const [automacao, setAutomacao] = useState<boolean | undefined>(lancamento?.automacao ?? false)
    const [cobrancaAutomatica, setCobrancaAutomatica] = useState(lancamento?.cobrancaAutomatica)
    const [recorrencia, setRecorrencia] = useState<string | null | undefined>(lancamento?.recorrencia)
    const [recorrenciaDia, setRecorrenciaDia] = useState<number | undefined | null>(lancamento?.recorrenciaDia)
    const [recorrenciaMes, setRecorrenciaMes] = useState<number | undefined | null>(lancamento?.recorrenciaMes)
    const [inicioAutomacao, setInicioAutomacao] = useState<string | undefined>(lancamento?.inicioAutomacao)
    const [formaCobranca, setFormaCobranca] = useState(lancamento?.formaCobranca)
    const [cobrancas, setCobrancas] = useState(false)
    const [parcelamento, setParcelamento] = useState(false)
    const [parcelas, setParcelas] = useState<number | null>(null)
    const [competenciaParcela, setCompetenciaParcela] = useState('')
    const [baixas, setBaixas] = useState(false)
    const [dataCobranca, setDataCobranca] = useState('')
    const [tipoCobranca, setTipoCobranca] = useState< string| undefined >(lancamento?.formaCobranca)
    const [valorCobranca, setValorCobranca] = useState(moedaMascara(lancamento?.saldoQuitar))
    const [processando, setProcessando] = useState (false)
    const [rateio, setRateio] = useState<rateio[]>(lancamento?.rateios ? lancamento.rateios : [{
        idCentro: '',
        idCategoria: '',
        valor: 0
    }])

    const modalCobrancas = () => {
        setCobrancas(!cobrancas)
    }

    const modalBaixas = () => {

        setBaixas(!baixas)

        return
    }

    function ocultarModal () {

        if(recorrenciaLancamento) {

            navigate(`${pathname}?tipo=${tipo}&recorrencia=${recorrenciaLancamento}`)

            return exibirModal ()
        }

        if(inadimplencia) {
            
            navigate(`${pathname}?tipo=${tipo}&inadimplencia=${inadimplencia}`)

            return exibirModal()
        }

        navigate(`${pathname}?tipo=${tipo}`)

        return exibirModal ()
    }


    const dataMinimaCobranca = () => {

        const hoje = new Date();
        
        const ano = hoje.getFullYear()
        const mes = hoje.getMonth() + 1
        const dia = hoje.getDate()

        const dataMinima = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`

        return dataMinima
    }

    const cadastrarLancamento = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(cadastro === undefined || descricao === undefined || valorTotal === undefined || competencia === undefined || vencimento === undefined || tipo === null) {
            return
        }

        const rateioConvertido = rateio.map((rateio) => {
            const novosDados = { ...rateio }

            novosDados.valor = converterNumero(rateio.valor)/100

            return novosDados
        })

        try {

            const payload = {
                idCadastro: cadastro,
                tipo,
                descricao,
                valorTotal: valorTotal/100,
                competencia,
                vencimento,
                formaCobranca,
                cobrancaAutomatica,
                parcelas,
                competenciaParcela,
                automacao,
                inicioAutomacao,
                recorrencia,
                recorrenciaDia,
                recorrenciaMes,
                rateio: rateioConvertido
            }

            const resposta = await novoLancamento(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                toast.success('Lançamento Cadastrado com Sucesso')

                return
            }
            
        } catch (error) {
            
            setProcessando(false)
            toast.error('Falha ao Cadastrar um Lançamentos')
            
            return
        }

    }

    const updateLancamento = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(id === null || cadastro === undefined || descricao === undefined || valorTotal === undefined || valorQuitado === undefined || competencia === undefined || vencimento === undefined) {
            return setProcessando(false)
        }

        const rateioConvertido = rateio.map((rateio) => {
            const novosDados = { ...rateio }

            novosDados.valor = converterNumero(rateio.valor)/100

            return novosDados
        })


        const payload = {
            idCadastro: cadastro,
            descricao,
            valorTotal: valorTotal/100,
            saldoQuitar: valorTotal/100 - valorQuitado/100,
            competencia,
            vencimento,
            formaCobranca,
            cobrancaAutomatica,
            automacao,
            inicioAutomacao,
            recorrencia,
            recorrenciaDia,
            recorrenciaMes,
            rateio: rateioConvertido
        }

        try {

            const resposta = await atualizarLancamento(id, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                toast.update('Dados Atualizados com Sucesso')
                atualizarDados()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falta ao Atualizar o Lançamento')

            return
        }
    }

    const gerarCobranca = async (e: FormEvent, idLancamento: number) => {

        e.preventDefault()
        setProcessando(true)

        if(tipoCobranca === undefined || tipoCobranca === null) {
            return setProcessando(false)
        }

        try {

            const payload = {
                tipo: tipoCobranca,
                dataCobranca,
                valorCobranca: converterNumero(valorCobranca)/100
            }

            const resposta = await novaCobranca(idLancamento, payload)

            if (resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                toast.success('Cobrança Gerada com Sucesso')

                return
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Gerar uma Nova Cobrança')

            return
            
        }
    }

    const visualizarCobrancaBoleto = async (idCobranca: number) => {

        setProcessando(true)

        try {

            const resposta = await visualizarCobranca(idCobranca)

            if (resposta.status === 200) {

                const dadosPdf = URL.createObjectURL(resposta.data)

                const pdf = document.createElement('a')

                pdf.href = dadosPdf
                pdf.download = 'boleto.pdf'

                document.body.appendChild(pdf)

                pdf.click()

                document.body.removeChild(pdf)

                setProcessando(false)
                atualizarDados()
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Processar a Solicitação')

            return
            
        }
    }

    const faturaWa = async (idCobranca: number) => {

        setProcessando(true)

        try {

            const resposta = await enviarCobrancaWa(idCobranca)

            if (resposta.status === 200) {

                setProcessando(false)
                toast.success('Mensagem Enviada com Sucesso')

                return
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Enviar a Cobrança no WhatsApp')

            return
            
        }
    }

    const linkPagamento = async (link: string | null) => {

        if(!link) {
            return setProcessando(false)
        }

        await toast.promise(
            navigator.clipboard.writeText(link), {
                pending: 'Copiando o Link',
                success: 'Link Copiado com Sucesso',
                error: 'Falha ao Copiar o Link'
            }
        )
    }

    const cancelarCobrancaBoleto = async (idCobranca: number) => {

        setProcessando(true)

        try {

            const resposta = await cancelarCobranca(idCobranca)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                toast.success('Cobrança Cancelada com Sucesso')
    
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Cancelar o Boleto')

            return
            
        }
    }

    const efetuarBaixa = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(id === null) {

            return setProcessando(false)
        }

        const cobrancasAtivas =  lancamento?.cobrancas.some(cobranca => cobranca.situacao === 'Em Cobrança')

        if(cobrancasAtivas) {
            return alert('Cancele as cobranças em andamento antes de continuar!')
        }

        try {

            const payload = {
                idLancamento: id,
                idConta: contaBaixa,
                dataTransacao,
                valor: converterNumero(valorBaixa)/100
            }

            const resposta = await baixarLancamento(payload)

            if (resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                toast.info('Baixa realizada com sucesso')

                return
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Baixar o Dados')

            return
        }

    }

    const estornarBaixa = async (id: string) => {

        setProcessando(true)

        try {

            const resposta = await apagarBaixa(id)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                toast.info('Estorno realizado com Sucesso')
                
            }
            
        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Estornar o Lançamento')

            return
            
        }
    }

    const changeValorBaixa = (e: ChangeEvent<HTMLInputElement>) => {

        return setValorBaixa(moedaMascara(e.target.value))
    }

    const changeValorCobranca = (e: ChangeEvent<HTMLInputElement>) => {

        return setValorCobranca(moedaMascara(e.target.value))
    }

    const adicionarCategoria = () => {

        const data = {
            idCentro: '',
            idCategoria: '',
            valor: 0
        }
        
        return setRateio(prevState => [...prevState, data])
    }

    const excluirCategoria = (posicao: number) => {

        const itens = [...rateio]

        itens.splice(posicao, 1)

        return setRateio(itens)

    }

    const changeRateio = (key: string, valor: string, index: number) => {

        let valorCentro = ''

        const centro = categorias.find((categoria => parseInt(categoria.id) === parseInt(valor)))

        if(tipo === 'receita') {
            valorCentro = centro?.centroResultado?.id ? centro.centroResultado.id : ''
        } else {
            valorCentro = centro?.centroCusto?.id ? centro.centroCusto.id : ''
        }

        const data = [...rateio]

        data[index] = {...data[index], [key]: valor, idCentro: valorCentro.toString()}

        return setRateio(data)
    }

    const changeRateioValor = (key: string, valor: string, index: number) => {

        const data = [...rateio]

        data[index] = {...data[index], [key]: moedaMascara(valor)}

        const novoValorTotal = data.reduce((total, item) => total + converterNumero(item.valor), 0);

        setRateio(data)
        setValorTotal(novoValorTotal)

        return
    }

    useEffect(() => {

        if (recorrencia === 'semanal' && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const dia = new Date(inicioAutomacao)

            setRecorrenciaDia(dia.getDay() + 1)
        }

        if ((recorrencia === "mensal" || recorrencia === 'trimestral' || recorrencia === "semestral" || recorrencia === "anual") && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const data = new Date(inicioAutomacao)

            const dia = new Date (data.setDate(data.getDate() + 1))

            setRecorrenciaDia(dia.getDate())
    
        }

        if ((recorrencia === "anual" || recorrencia === 'trimestral' || recorrencia === "semestral") && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const data = new Date(inicioAutomacao)

            const mes = new Date (data.setMonth(data.getMonth() + 1))

            setRecorrenciaMes(mes.getMonth())

        }

    }, [recorrencia, inicioAutomacao])

    useEffect(() => {

        const verificarParcelas = () => {

            if(automacao) {
                setParcelas(null)
                setParcelamento(false)
            }
        }

        verificarParcelas()

    }, [automacao])

    useEffect(() => {
        
        const buscarBaixas = async () => {

            if(id === null) {
                return
            }

            try {

                const resposta = await listarBaixas(id)

                if(resposta.status === 200) {
                    setBaixasLancamentos(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarBaixas()

    }, [id, atualizarInformacoes])

    useEffect(() => {

        const buscarCadastros = async () => {

            try {

                const params = {
                    tipo: '',
                    termo: '',
                    pagina: 1,
                    situacao: ''
                }

                const resposta = await listarCadastros(params)

                if (resposta.status === 200) {
                    return setCadastros(resposta.data)
                }
                
            } catch (error) {

                return alert('Erro ao Listar os Clientes')
                
            }
        }

        buscarCadastros ()

    }, [])

    useEffect(() => {

        const buscarCategorias = async () => {

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa'
                }

                const resposta = await lisatarCategorias(params)

                if(resposta.status === 200) {
                    return setCategorias(resposta.data)
                }
                
            } catch (error) {

                return alert('Falta ao Listar as Categorias')
                
            }
        }

        buscarCategorias ()

    }, [])

    useEffect(() => {

        const buscarContas = async () => {

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa'
                }

                const resposta = await listarContas(params)

                if(resposta.status === 200) {
                    setProcessando(false)

                    return setContasBancarias(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarContas()

    }, [])


    return (
        <div id = 'fundoModal'>
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={taskImg} alt='' />
                    <p>{!lancamento ? 'Novo Lançamento': 'Vizualizar Lançamento'}</p>
                </span>
                <form id='formModalNovo' onSubmit={lancamento ? updateLancamento : cadastrarLancamento}>
                    <section>
                        {lancamento && <span>
                            <DivSituacao texto={lancamento.situacao} cor={lancamento.situacao.toLowerCase().replace(/\s+/g, '')}/>
                        </span>}
                        <span>
                            <label htmlFor="valorTotal">{tipo === 'receita' ? 'Valor Total a Receber':'Valor Total a Pagar'}</label>
                            <input
                                id='valorTotal'
                                value={valorTotal === undefined ? moedaMascara(0) : moedaMascara(valorTotal)}
                                disabled
                                type='text' 
                                required/>
                        </span>
                    </section>

                    {lancamento && <section>
                        <span>
                                <label htmlFor="valorTotal">{tipo === 'receita' ? 'Valor Recebido' : 'Valor Pago'}</label>
                            <input
                                id='valorTotal'
                                value={valorQuitado === undefined ? moedaMascara(0) : moedaMascara(valorQuitado)}
                                disabled 
                                type='text' />
                        </span>
                        <span>
                            <label htmlFor="valorTotal">Saldo em Aberto</label>
                            <input
                                id='valorTotal'
                                value={saldoQuitar === undefined ? moedaMascara(0) : moedaMascara(saldoQuitar)}
                                disabled 
                                type='text'/>
                        </span>
                    </section>}
                    <section>
                        <span>
                            <label htmlFor="clienteLancamento">{tipo === 'receita' ? 'Cliente' : 'Fornecedor/Colaborador'}</label>
                            <select id='clienteLancamento' value={cadastro} onChange={(e) =>setCadastro(e.target.value)} required>
                                <option value=''></option>
                                {tipo === 'receita' ? cadastros.filter(cadastro => cadastro.tipo === 'cliente').map((cadastro) => (
                                    <option key={cadastro.id} value={cadastro.id}>{cadastro.nomeFantasia}</option>
                                )): cadastros.filter(cadastro => cadastro.tipo !== 'cliente').map((cadastro) => (
                                    <option key={cadastro.id} value={cadastro.id}>{cadastro.nomeFantasia}</option>))}
                            </select>
                        </span>

                        <span>
                            <label htmlFor="competenciaLancamento">Competência</label>
                            <input 
                                id="competenciaLancamento" 
                                type='date'
                                value={competencia === undefined ? '' : competencia}
                                onChange={(e) => setCompetencia(e.target.value)}
                                required/>
                        </span>
                        <span>
                            <label htmlFor="vencimentoLancamento">Vencimento</label>
                            <input 
                                id="vencimentoLancamento" 
                                type='date'
                                value={vencimento === undefined ? '' : vencimento}
                                onChange={(e) => setVencimento(e.target.value)}
                                required/>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="descricaoLancamento">Descrição</label>
                            <input
                                id='descricaoLancamento'
                                value={descricao === undefined ? '' : descricao}
                                onChange={(e) => setDescricao(e.target.value)} 
                                type='text' 
                                required/>
                        </span>
                    </section>
                    
                    {rateio.map((dado, index) => (
                        <section key={index}>
                            <span>
                                <label>Categoria</label>
                                <select value={dado.idCategoria} onChange={(e) => changeRateio('idCategoria', e.target.value, index)} required>
                                    <option value=''></option>
                                    {categorias.filter(categoria => categoria.tipo === tipo).map((categoria) => (
                                        <option key={categoria.id} value={categoria.id}>{categoria.descricao}</option>
                                    ))}
                                </select>
                            </span>
                            <span>
                                <label htmlFor="">{tipo === 'receita' ? 'Forma Recebimento' : 'Forma Pagamento'}</label>
                                <select value={formaCobranca !== null ? formaCobranca : ''} onChange={(e) => setFormaCobranca(e.target.value)} required>
                                    <option value=""></option>
                                    <option value="Pix">Pix</option>
                                    <option value="Boleto">Boleto Bancário</option>
                                    <option value="Transferência Bancária">Transferência Bancária</option>
                                    <option value="Cartão de Crédito">Cartão de Crédito</option>
                                    <option value="Cartão de Débito">Cartão de Débito</option>
                                    <option value="Dinheiro">Dinheiro</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </span>
                            <span>
                                <label>Valor</label>
                                <input
                                    value={dado.valor === 0 ? null : moedaMascara(dado.valor)}
                                    onChange={(e) => changeRateioValor('valor', e.target.value, index)}
                                    placeholder='R$ 0,00'
                                    type="text" 
                                    required/>
                            </span>
                            {index !== 0 && !lancamento ? <span>
                                <img id='apagarIconeCategoriaLancamento' title='Excluir Categoria' src={apagarImg} alt='' onClick={() => excluirCategoria(index)}/>
                            </span>: null}
                        </section>
                    ))}

                    {!lancamento && <p id='adicionarCategoriaLancamento' onClick={adicionarCategoria}>+ Adicionar Categoria</p>}

                    {tipo === 'receita' && <div className='tarefasRecorrentes'>
                        <input
                            checked={cobrancaAutomatica}
                            className='toggleCobrancaAutomatica'
                            id='toggleCobrancaAutomatica'
                            type="checkbox"
                            onChange={() => setCobrancaAutomatica(!cobrancaAutomatica)}/>
                        <label id='toggleCobrancaAutomatica' htmlFor='toggleCobrancaAutomatica'></label>
                        <p>Cobrança Automática</p>
                    </div>}
                    <div className='tarefasRecorrentes'>
                        <input
                            checked={automacao}
                            className='toggleAutomacaoTarefas'
                            id='toggleAutomacaoTarefas'
                            type="checkbox"
                            onChange={() => setAutomacao(!automacao)}/>
                        <label id='toggleAutomacaoTarefas' htmlFor='toggleAutomacaoTarefas'></label>
                        <p>Recorrência</p>
                    </div>
                    {!automacao && !lancamento && <div className='tarefasRecorrentes'>
                        <input
                            checked={parcelamento}
                            className='toggleParcelamentoLancamento'
                            id='toggleParcelamentoLancamento'
                            type="checkbox"
                            onChange={() => setParcelamento(!parcelamento)}/>
                        <label id='toggleParcelamentoLancamento' htmlFor='toggleParcelamentoLancamento'></label>
                        <p>Parcelamento</p>
                    </div>}

                    {automacao && !parcelamento && <section className='automacaoTarefas'>
                        <span>
                            <label htmlFor="inicioAutomacao">PRÓXIMO VENCIMENTO</label>
                            <input 
                                id='inicioAutomacao'
                                type='date'
                                value={inicioAutomacao !== null ? inicioAutomacao : ''}
                                onChange={(e) => setInicioAutomacao(e.target.value)} 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="">Frequência</label>
                            <select value={recorrencia !== null ? recorrencia : ''} onChange={(e) => setRecorrencia(e.target.value)} required>
                                <option value=""></option>
                                <option value="diaria">Diária</option>
                                <option value="semanal">Semanal</option>
                                <option value="mensal">Mensal</option>
                                <option value="trimestral">Trimestral</option>
                                <option value="semestral">Semestral</option>
                                <option value="anual">Anual</option>
                            </select>
                        </span>
                    </section>}

                    {parcelamento && !lancamento && <section className='automacaoTarefas'>
                        <span>
                            <label htmlFor="lancamentoParcelas">PARCELAS</label>
                            <input 
                                id='lancamentoParcelas'
                                type='number'
                                min={2}
                                placeholder='Digite o número de parcelas'
                                value={parcelas === null ? 0 : parcelas}
                                onChange={(e) => setParcelas(parseInt(e.target.value))} 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="">Competência da Parcela</label>
                            <select value={competenciaParcela} onChange={(e) => setCompetenciaParcela(e.target.value)} required>
                                <option value=""></option>
                                <option value="Mês do Vencimento">Mês do Vencimento</option>
                                <option value="Mês do Lançamento">Mês do Lançamento</option>
                            </select>
                        </span>
                    </section>}

                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        {lancamento && lancamento.tipo === 'receita' && <Button type='button' cor='cobrancasLancamentos' isLoading={processando} onClick={modalCobrancas}>Cobranças</Button>}
                        {lancamento && <Button type='button' cor='baixarLancamento' isLoading={processando} onClick={modalBaixas}>{tipo === 'receita' ? 'Recebimentos' : 'Pagamentos'}</Button>}
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>

            {cobrancas && tipo === 'receita' && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={taskImg} alt='' />
                    <p>Visualizar Cobranças</p>
                </span>
                <form className='formModalNovaCobrancas' onSubmit={(e) => gerarCobranca(e, Number(lancamento?.id))}>
                    <section>
                        <span>
                            <label htmlFor="cadastroBaixa">Receber de</label>
                            <input
                                id='cadastroBaixa'
                                value={lancamento?.cadastro.nomeFantasia === undefined ? '' : lancamento.cadastro.nomeFantasia} 
                                type='text' 
                                disabled/>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="descricaoLancamento">Descrição</label>
                            <input
                                id='descricaoLancamento'
                                value={descricao === undefined ? '' : descricao}
                                type='text' 
                                disabled/>
                        </span>
                        <span>
                            <label htmlFor="valorTotal">Saldo em Aberto</label>
                            <input
                                id='valorTotal'
                                value={saldoQuitar === undefined ? '' : moedaMascara(saldoQuitar)}
                                disabled 
                                type='text'/>
                        </span>
                    </section>
                    {lancamento?.situacao !== 'Recebido' ? <section>
                        <p className='tituloModalbaixarLancamento'>Gerar Cobrança</p>
                    </section> : null}

                    {lancamento?.situacao !== 'Recebido' && <section>
                        <span>
                            <label htmlFor="contaTransacao">Forma de {tipo === 'receita' ? 'Recebimento' : 'Pagamento'}</label>
                            <select id="contaTransacao" value={tipoCobranca} onChange={(e) => setTipoCobranca(e.target.value)} required>
                                <option value=""></option>
                                <option value='Boleto Bancário'>Boleto Bancário</option>
                                <option value='Cartão'>Cartão</option>
                                <option value='Pix'>Pix</option>
                                <option value='Transferência Bancária'>Transferência Bancária</option>
                                <option value='Dinheiro'>Dinheiro</option>
                                <option value='Outros'>Outros</option>
                            </select>
                        </span>
                        <span>
                            <label htmlFor="dataCobranca">Data do Recebimento</label>
                            <input 
                                id="dataCobranca"
                                value={dataCobranca}
                                min={dataMinimaCobranca()}
                                onChange={(e) => setDataCobranca(e.target.value)} 
                                type="date"
                                required/>
                        </span>
                        <span>
                            <label htmlFor="valorCobranca">Valor da Cobrança</label>
                            <input
                                id='valorCobranca'
                                value={moedaMascara(valorCobranca)}
                                onChange={(e) => changeValorCobranca(e)}
                                type='text' />
                        </span>
                        <span>
                            <Button type='submit' cor='salvar' isLoading={processando}>
                                {!processando && 'Gerar Cobrança'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>
                    </section>}
                </form>

                <form className='formModalBaixas'>
                    <p className='tituloModalbaixarLancamento'>{tipo === 'receita' ? 'Cobranças Geradas' : 'Pagamentos Efetuados'}</p>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th className='ladoEsquerdoContent' align='left'>Data da Cobranca</th>
                                <th className='contaBaixaLancamento' align='left'>Tipo</th>
                                <th className = 'valorBaixaLancamento' align='left'>Valor</th>
                                <th className = 'valorBaixaLancamento' align='left'>Data do Recebimento</th>
                                <th className = 'valorBaixaLancamento' align='left'>Situação</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lancamento?.cobrancas.map((cobranca) => (
                                <tr key={cobranca.id}>
                                    <td className='primeiraColuna'>{cobranca.dataCobranca.slice(8,10)}-{cobranca.dataCobranca.slice(5,7)}-{cobranca.dataCobranca.slice(0,4)}</td>
                                    <td className='contaBaixaLancamento'>{cobranca.tipo}</td>
                                    <td className='contaBaixaLancamento'>{cobranca.valor?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                    <td className='contaBaixaLancamento'>{cobranca.dataRecebimento?.slice(8,10)}-{cobranca.dataRecebimento?.slice(5,7)}-{cobranca.dataRecebimento?.slice(0,4)}</td>
                                    <td>
                                        <DivSituacao texto={cobranca.situacao} cor={cobranca.situacao.toLowerCase().replace(/\s+/g, '')} />
                                    </td>
                                    <td id='ladoDireitoContent' className='opcoesCobrancasContainer' align='center'>
                                        {cobranca.situacao !== 'Cancelada' && cobranca.situacao !== 'Paga' && <img id='apagarIconeTarefa' title={cobranca.tipo === 'Boleto Bancário' ? 'Visualizar Boleto' : 'Copiar Link'} src={cobranca.tipo === 'Boleto Bancário' ? boletoImg : copiarImg } alt='' onClick={cobranca.tipo === 'Boleto Bancário' ? () => visualizarCobrancaBoleto(Number(cobranca.id)) : () => linkPagamento(cobranca.linkPagamento)}/>}
                                        {cobranca.situacao !== 'Cancelada' && cobranca.situacao !== 'Paga' && <img id='apagarIconeTarefa' title='Enviar no WhatsApp' src={waImg} alt='' onClick={() => faturaWa(Number(cobranca.id))}/>}
                                        {cobranca.situacao !== 'Cancelada' && cobranca.situacao !== 'Paga' && <img id='apagarIconeTarefa' title= 'Cancelar Cobbrança' src={apagarImg} alt='' onClick={() => cancelarCobrancaBoleto(Number(cobranca.id))}/>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span className='botoesContainerBaixasLancamentos'>
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={modalCobrancas}>Sair</Button>
                    </span>
                </form>
            </section>}

            {baixas && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={taskImg} alt='' />
                    <p>Vizualizar Baixas</p>
                </span>
                <form className='formModalNovaBaixa' onSubmit={efetuarBaixa}>
                    <section>
                        <span>
                            <label htmlFor="cadastroBaixa">{tipo === 'receita' ? 'Receber de' : 'Pagar a'}</label>
                            <input
                                id='cadastroBaixa'
                                value={lancamento?.cadastro.nomeFantasia === undefined ? '' : lancamento.cadastro.nomeFantasia} 
                                type='text' 
                                disabled/>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="descricaoLancamento">Descrição</label>
                            <input
                                id='descricaoLancamento'
                                value={descricao === undefined ? '' : descricao}
                                type='text' 
                                disabled/>
                        </span>
                        <span>
                            <label htmlFor="valorTotal">Saldo em Aberto</label>
                            <input
                                id='valorTotal'
                                value={saldoQuitar === undefined ? '' : moedaMascara(saldoQuitar)}
                                disabled 
                                type='text'/>
                        </span>
                    </section>
                    {lancamento?.situacao !== 'Recebido' && <section>
                        <p className='tituloModalbaixarLancamento'>Efetuar Baixa</p>
                    </section>}

                    {lancamento?.situacao !== 'Recebido' && <section>
                        <span>
                            <label htmlFor="dataTransacao">Data do {tipo === 'receita' ? 'Recebimento': 'Pagamento'}</label>
                            <input 
                                id="dataTransacao"
                                value={dataTransacao}
                                onChange={(e) => setDataTransacao(e.target.value)} 
                                type="date"
                                required/>
                        </span>
                        <span>
                            <label htmlFor="contaTransacao">Conta do {tipo === 'receita' ? 'Recebimento' : 'Pagamento'}</label>
                            <select id="contaTransacao" value={contaBaixa} onChange={(e) => setContaBaixa(e.target.value)} >
                                <option value=""></option>
                                {contasBancarias.map((conta) => (
                                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                ))}
                            </select>
                        </span>
                        <span>
                            <label htmlFor="valorTransacao">Valor {tipo === 'receita' ? 'Recebido': 'Pago'}</label>
                            <input
                                id='valorTransacao'
                                value={moedaMascara(valorBaixa)}
                                onChange={(e) => changeValorBaixa(e)}
                                type='text' />
                        </span>
                        <span>
                            <Button type='submit' cor='salvar' isLoading={processando}>
                                {!processando && 'Salvar Dados'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>
                    </section>}
                </form>

                <form className='formModalBaixas'>
                    <p className='tituloModalbaixarLancamento'>{tipo === 'receita' ? 'Valores Recebidos' : 'Pagamentos Efetuados'}</p>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th className='ladoEsquerdoContent' align='left'>Data do {tipo === 'receita' ? 'Recebimento' : 'Pagamento'}</th>
                                <th className='contaBaixaLancamento' align='left'>Conta</th>
                                <th className = 'valorBaixaLancamento' align='left'>Valor</th>
                                <th align='left'>{tipo === 'receita' ? 'Recebido por' : 'Pago por'}</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {baixasLancamentos.map((baixa) => (
                                <tr key={baixa.id}>
                                    <td className='primeiraColuna'>{baixa.dataTransacao.slice(8,10)}-{baixa.dataTransacao.slice(5,7)}-{baixa.dataTransacao.slice(0,4)}</td>
                                    <td className='contaBaixaLancamento'>{baixa.conta.descricao}</td>
                                    <td className='valorBaixaLancamento'>{tipo === 'receita' ? moedaMascara(baixa.valor) : `- ${moedaMascara(baixa.valor)}`}</td>
                                    <td className='usuarioResponsavelContent'>
                                        <img className='usuarioResponsavel' src={baixa.usuario.avatar ? baixa.usuario.avatar : avatarDefault} alt='' />
                                        {baixa.usuario.nome}
                                    </td>
                                    <td id='ladoDireitoContent' align='center'>
                                        <img id='apagarIconeTarefa' title={tipo === 'receita' ? 'Estornar Recebimento' : 'Estornar Pagamento'} src={apagarImg} alt='' onClick={() => estornarBaixa(baixa.id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span className='botoesContainerBaixasLancamentos'>
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={modalBaixas}>Sair</Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalLancamento
